// 3rd
import { progressAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

// App - Other
import { detail, small, tiny } from './text';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

// ########
// TAG BASE
// ########

const baseStyle = definePartsStyle({
  label: {
    color: 'text.primary',
  },

  track: {},

  filledTrack: {
    transition: 'all 180ms ease-in-out',
  },
});

// #####
// SIZES
// #####

const smSize = definePartsStyle({
  label: {
    ...tiny,
  },

  track: {
    h: '6px',
  },

  filledTrack: {},
});

const mdSize = definePartsStyle({
  label: {
    ...small,
  },

  track: {
    h: '12px',
  },

  filledTrack: {},
});

const lgSize = definePartsStyle({
  label: {
    ...detail,
  },

  track: {
    h: '20px',
  },

  filledTrack: {},
});

// ########
// VARIANTS
// ########

const primaryVariant = definePartsStyle({
  label: {},

  track: {
    py: 'xs',
    px: 'xs',
    bg: 'surface.high-tone',
    borderRadius: 'xs',
  },

  filledTrack: {
    bgColor: 'surface.brand.primary', // INSIGHT <=== this must be bgColor as otherwise it overrides background image if using hasStripe prop
    borderRadius: '2px !important',
  },
});

const secondaryVariant = definePartsStyle({
  label: {},

  track: {
    py: 'xs',
    px: 'xs',
    bg: 'surface.high-tone',
    borderRadius: 'xs',
  },

  filledTrack: {
    bgColor: 'surface.brand.secondary', // INSIGHT <=== this must be bgColor as otherwise it overrides background image if using hasStripe prop
    borderRadius: '2px !important',
  },
});

const roundedPrimaryVariant = definePartsStyle({
  label: {},

  track: {
    bg: 'surface.mid-tone',
    borderRadius: 'md',
  },

  filledTrack: {
    bgColor: 'surface.brand.primary-mid-contrast', // INSIGHT <=== this must be bgColor as otherwise it overrides background image if using hasStripe prop
    borderTopEndRadius: 'none !important',
    borderBottomEndRadius: 'none !important',
  },
});

const roundedSecondaryVariant = definePartsStyle({
  label: {},

  track: {
    bg: 'surface.mid-tone',
    borderRadius: 'md',
  },

  filledTrack: {
    bgColor: 'surface.brand.secondary-mid-contrast', // INSIGHT <=== this must be bgColor as otherwise it overrides background image if using hasStripe prop
    borderTopEndRadius: 'none !important',
    borderBottomEndRadius: 'none !important',
  },
});

export const progressVariants = {
  primary: primaryVariant,
  'rounded.primary': roundedPrimaryVariant,
  secondary: secondaryVariant,
  'rounded.secondary': roundedSecondaryVariant,
} as const;
export const progressSizes = { sm: smSize, md: mdSize, lg: lgSize } as const;
export const progressDefaultProps = {
  variant: 'rounded.primary',
  size: 'md',
} as const;

const progressTheme = defineMultiStyleConfig({
  baseStyle,
  variants: progressVariants,
  sizes: progressSizes,
  defaultProps: progressDefaultProps,
} as const);

export const Progress = progressTheme;
